import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-100 text-[#295dcd] rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-4xl font-bold mb-8 text-[#295dcd]`}
  }
  input,
  textarea {
    ${tw`w-full bg-gray-200 text-gray-800 text-base font-medium tracking-wide border-gray-300 border py-2 px-4 rounded-md focus:outline-none focus:border-pink-400 transition duration-200`};

    ::placeholder {
      ${tw`text-gray-600`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between gap-6`;
const Column = tw.div`sm:w-1/2 flex flex-col`;
const InputContainer = tw.div`relative py-2`;
const Label = tw.label`block text-sm font-semibold text-gray-700 mb-2`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full mt-6 py-3 bg-blue-800 text-white rounded font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-blue-900 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`;

export default () => {
  return (
    <Container id="contact">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-3xl">
            <h2>CONTACT <span tw="text-blue-400">US</span></h2>
            <form action="#">
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="first-name-input">First Name</Label>
                    <Input
                      id="first-name-input"
                      type="text"
                      name="first-name"
                      placeholder="Name..."
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="last-name-input">Last Name</Label>
                    <Input
                      id="last-name-input"
                      type="text"
                      name="last-name"
                      placeholder="Name..."
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>
              <InputContainer>
                <Label htmlFor="email-input">Email</Label>
                <Input
                  id="email-input"
                  type="email"
                  name="email"
                  placeholder="you@email.com"
                />
              </InputContainer>
              <InputContainer>
                <Label htmlFor="message-input">Message</Label>
                <TextArea
                  style={{ paddingBottom: 80 }}
                  id="message-input"
                  name="message"
                  placeholder="Your message..."
                />
              </InputContainer>
              <SubmitButton type="submit">Send Message</SubmitButton>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};