import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Import Link for navigation

import Linkdin from "images/Linkedin.svg";
import logo from "../../images/PA-Logo-bg-white.png";
import PAInfo from "../../images/ContactInfo-PASoftwareWebsite.png"
import PAAdress from "../../images/Address-PASoftwareWebsite.png"

const Container = tw.div`relative bg-[#ffde00] text-black px-8 py-5`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;

const FooterGrid = tw.div`grid grid-cols-1 md:grid-cols-3 gap-8 items-start`;
const Column = tw.div`flex flex-col items-start`;
const LogoContainer = tw.div`flex items-center mb-1`;
const LogoImg = tw.img`w-12 h-12`;
const LogoText = tw.h5`text-2xl font-black tracking-wide text-black`;
const SectionContainer = tw.div`grid grid-rows-2 grid-flow-col gap-4 mt-5 `
const SectionDiv = tw.div`row-span-1 md:row-span-2`
const SocialLinksContainer = tw.div`flex mt-1`;

const SocialLink = styled.a`
  ${tw`cursor-pointer rounded-full text-gray-900 transition duration-300`}
  img {
    ${tw`w-6 h-6`}
    transition: transform 300ms ease;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const SectionTitle = tw.label`font-bold text-2xl text-black`;
const InfoImage = tw.img`mt-0 max-w-full`;

const LocateUsLink = styled.a`
  ${tw`mt-0 text-lg font-semibold text-blue-600 underline cursor-pointer`}
`;

const StyledLink = styled(Link)`
${tw`mt-0 text-lg font-semibold text-blue-600 hover:underline cursor-pointer`}
`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 font-medium text-gray-700 border-t border-gray-300 pt-4`;

export default () => {
  return (
    <Container>
      <Content>
        <FooterGrid style={{ display: "flex", justifyContent: "space-between" }}>
          <Column>
            <LogoContainer>
              {/* <LogoImg src={logo} alt="Logo" /> */}
              <LogoText>PA Softwares</LogoText>
            </LogoContainer>
            <SocialLinksContainer>
              <SocialLink
                target="_blank"
                href="https://www.linkedin.com/company/pa-softwares/"
              >
                <img
                  src={Linkdin}
                  alt="LinkedIn Icon"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    transition: "transform 300ms ease",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                  onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                />
                LinkedIn
              </SocialLink>
            </SocialLinksContainer>
          </Column>

          <Column>
            <SectionTitle>Locate Us</SectionTitle>
            <LocateUsLink href="https://goo.gl/maps/rq1S24SwhXe9ShaB7" target="_blank">
              PASoftwares Office location in Google Maps
            </LocateUsLink>
            <SectionContainer>
              <SectionDiv>
                <SectionTitle>Our Info</SectionTitle>
                <img src={PAInfo} alt="Our Info" style={{width: '80%'}} />
              </SectionDiv>
              <SectionDiv>
                <SectionTitle>Our Address</SectionTitle>
                <img src={PAAdress} alt="Our Address" style={{width: '80%'}} />
              </SectionDiv>
            </SectionContainer>
          </Column>

          <Column>
            <SectionTitle>Quick Links</SectionTitle>
            <StyledLink to="/privacy">Privacy Policy</StyledLink>
          </Column>
        </FooterGrid>
        <CopywrightNotice>
          &copy; 2025 PA Softwares All Rights Reserved.
        </CopywrightNotice>
      </Content>
    </Container>
  );
};
