import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/PAHeader.js";
import Footer from "components/footers/PAFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

import PT from "../images/Apps/sc10.png";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./customGallery.css";
import Pulse_1 from "../images/Pulse/pulse_1.png"
import Pulse_2 from "../images/Pulse/pulse_2.png"
import Pulse_3 from "../images/Pulse/pulse_3.png"
import Pulse_4 from "../images/Pulse/pulse_4.png"




const Subheading = tw.span`uppercase tracking-wider text-[#ffde00] text-sm`;
export default () => {
  const images = [
    {
      original: Pulse_1,
      thumbnail: Pulse_1,
    },
    {
      original: Pulse_2,
      thumbnail: Pulse_2,
    },
    {
      original: Pulse_3,
      thumbnail: Pulse_3,
    },
    {
      original: Pulse_4,
      thumbnail: Pulse_4,
    },

  ];



  return (
    <div>
      <div tw="h-20">
        <Header />
      </div>
      <MainFeature1
        subheading={<Subheading>About Pulse</Subheading>}
        description="Pulse is the app developed for BHEL in collaboration with an IoT startup to view the data from various sensors they use in their manufacturing plants. When any of the sensors data reaches a critical point, for e.g., temperature goes beyond a certain limit, alerts get created. This app is only available for BHEL employees. They can view the each sensor data in real time and all the data collected in past time from the graphs."
        heading="Pulse"
        imageSrc={PT}
      />
      <div tw="p-10 pt-0">
        <ImageGallery items={images} showPlayButton={false} />
      </div>
      <Footer />
    </div>
  );
};
