import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/PAHeader.js";

import Footer from "components/footers/PAFooter.js";

import MainFeature1 from "components/features/TwoColWithButton.js";

import Scada_1 from "../images/Scada/Scadaf.jpg";
import Scada_2 from "../images/Scada/Scada2.jpg";
import Scada_3 from "../images/Scada/Scada3.jpg";
import Scada_4 from "../images/Scada/Scada4.jpg";
import Scada_5 from "../images/Scada/Scada5.jpg";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./customGallery.css"
const Subheading = tw.span`uppercase tracking-wider text-[#ffde00] text-sm`;

const images = [
  {
    original: Scada_1,
    thumbnail: Scada_1,
  },
  {
    original: Scada_2,
    thumbnail: Scada_2,
  },
  {
    original: Scada_3,
    thumbnail: Scada_3,
  },
  {
    original: Scada_4,
    thumbnail: Scada_4,
  },
  {
    original: Scada_5,
    thumbnail: Scada_5,
  },
];

export default () => {

  return (
    <div>
      <div tw="h-20">
        <Header />
      </div>

      <MainFeature1
        subheading={<Subheading>About SCADA</Subheading>}
        heading="SCADA for Transformer and Reactor Testing."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc={Scada_1}
      />
      <div tw="p-10 pt-0">
        <ImageGallery
          items={images}
          showPlayButton={false}

        />
      </div>
      <Footer />
    </div>
  );
};