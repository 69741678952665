import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/PASoftwareHero.js";
import Features from "components/features/PASecure.js";

import FeatureWithSteps from "components/features/PAStepsProcess.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";

import Footer from "components/footers/PAFooter.js";

import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import Prabal from "images/prabal.jpg";
import Rahul from "images/rahul.jpg";

import DashedBorderSixFeatures from "components/features/PATypesofApplication";
import TabCardGrid from "components/cards/PAProjectCompleted";
import SimpleContactUs from "components/forms/PAContactUs";

import ProfileThreeColGrid from "components/cards/PATechnologies";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-[#fff000]`;
  const HighlightedText = tw.span`text-[#295dcd]`;

  return (
    <div tw="">
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <DashedBorderSixFeatures />
        <Features
          subheading={<Subheading></Subheading>}
          heading={
            <>
              We build applications which <HighlightedText>are</HighlightedText>
            </>
          }
        />

        <TabCardGrid
          subheading={<Subheading></Subheading>}
          heading={
            <>
              Projects <HighlightedText>Completed.</HighlightedText>
            </>
          }
        />
        {/* <FeatureWithSteps
          subheading={<Subheading>STEPS</Subheading>}
          heading={
            <>
              Our <HighlightedText>Process.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        /> */}
        <div id="testimonials">
          <Testimonial

            subheading={<Subheading>Testimonials</Subheading>}
            heading={
              <>
                Our Clients <HighlightedText>Love Us.</HighlightedText>
              </>
            }
            testimonials={[
              {
                stars: 5,
                profileImageSrc: Prabal,
                heading: "Amazing User Experience",
                quote:
                  "They built the mobile app for canopi. The UI and functionality of the app is as expected. There were change of requirements in the middle. They handled it smoothly",
                customerName: "Prabal Krishna",
                customerTitle: "Co-Founder, Canopi India Private Limited",
              },
              {
                stars: 5,
                profileImageSrc: Rahul,

                heading: "Love the Developer Experience and Design Principles !",
                quote:
                  "They developed mobile application and gave back end support for the pulse application. Their developers are highly talented, thier support helped us to complete the project succesfully and deliver it on time.",
                customerName: "Rahul Raghunathan",
                customerTitle: "Founder of ExactSpace Technologies Pvt Ltd",
              },
            ]}
          /></div>
        {/* <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        ]} /> */}
        {/* <ProfileThreeColGrid heading={
          <>
            Technologies <HighlightedText>We Use.</HighlightedText>
          </>
        } /> */}
        <SimpleContactUs /></AnimationRevealPage>
      <Footer />

    </div>
  );
};