import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/PAHeader.js";
import "../index.css";
import Footer from "components/footers/PAFooter.js";

import MainFeature1 from "components/features/TwoColWithButton.js";
import PT from "../images/Apps/sc6.png";

import Startup_1 from "../images/Connect/Startup-1.png"
import Startup_2 from "../images/Connect/Startup-2.png"
import Startup_3 from "../images/Connect/startup-3.png"
import Startup_4 from "../images/Connect/connect_4.png"
import Startup_5 from "../images/Connect/connect_5.png"
import Startup_6 from "../images/Connect/connect_6.png"
import Startup_7 from "../images/Connect/connect_7.png"
import Startup_8 from "../images/Connect/connect_8.png"

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./customGallery.css"
const Subheading = tw.span`uppercase tracking-wider text-[#ffde00] text-sm`;


const images = [
  {
    original: Startup_1,
    thumbnail: Startup_1,
  },
  {
    original: Startup_2,
    thumbnail: Startup_2,
  },
  {
    original: Startup_3,
    thumbnail: Startup_3,
  },
  {
    original: Startup_4,
    thumbnail: Startup_4,
  },
  {
    original: Startup_5,
    thumbnail: Startup_5,
  },
  {
    original: Startup_6,
    thumbnail: Startup_6,
  },
  {
    original: Startup_7,
    thumbnail: Startup_7,
  },
  {
    original: Startup_8,
    thumbnail: Startup_8,
  },

]





export default () => {
  return (
    <div>
      <div tw="h-20">
        <Header />
      </div>
      <MainFeature1
        subheading={<Subheading>About Startup Connect</Subheading>}
        heading="Startupconnect is a networking platform for startups in a coworking place ."
        description="Startupconnect is a networking platform for startups in a coworking place.It involves features such as
 Listing all the startups in a coworking space with name, description, external links like website, facebook, 
 Listing of founders and employees of the startups with their one line description of profession, company name and contact. 
 It involves messaging feature for users, implemented with firebase.
 Search for people with a particular skill, 
 Search for startups with their name, industry, key areas.
 My Profile for founders and employees"
        imageSrc={PT}
      />
      <div tw="p-10 pt-0">
        <ImageGallery
          items={images}
          showPlayButton={false}


        />
      </div>

      <Footer />
    </div>
  );
};
