import { createGlobalStyle } from 'styled-components';
import { globalStyles } from 'twin.macro';

const GlobalStyles = createGlobalStyle(globalStyles, `
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 10;
    width: 100vw;
    overflow-x: hidden;
  }

  html {
    scroll-behavior: smooth;
    width: 100%;
    overflow-x: hidden;
  }

  #root {
    width: 100%;
    overflow-x: hidden;
  }

  // /* Below animations are for modal created using React-Modal */
  // .ReactModal__Overlay {
  //   transition: transform 300ms ease-in-out;
  //   transition-delay: 100ms;
  //   transform: scale(0);
  // }
  // .ReactModal__Overlay--after-open {
  //   transform: scale(1);
  // }
  // .ReactModal__Overlay--before-close {
  //   transform: scale(0);
  // }
`);

export default GlobalStyles;